import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import LoadingImage from "./assets/images/Logo with Circle.png"

const DesktopOne = React.lazy(() => import("./pages/DesktopOne/index"));
const Review = React.lazy(() => import ("./pages/Review/Review"));
const Shop = React.lazy(() => import ("./pages/Shop/Shop"));
const Terms = React.lazy(() => import ("./pages/Terms&Conditions/Terms"));
const Copyright = React.lazy(() => import("./pages/Copyrights/Copyright"));
const Cart = React.lazy(() => import("./components/Cart/Cart"));
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>
     <div>
      <img src={LoadingImage} alt='brandImage'  className="bg-white flex flex-col items-center justify-end max-w-[250px] mt-[59px] mx-auto p-3 md:px-5 rounded-lg w-full"
      sx={{ display: { xs: 'none', md: 'flex', sm: 'none', lg:'flex' }, mr: 1 }} height= "100px" width="175px"/> 
     </div>
    </>}>
      <Router>
        <Routes>
          <Route path="/" element={<DesktopOne />} />
          {/* <Route path="/Review" element={<Review/>} /> */}
          <Route path="/Shop" element='https://www.amazon.in/dp/B0CBBR9J9Y?ref=myi_title_dp' />
          <Route path="/NotFound" element={<NotFound />} />
          <Route path="/Terms" element={<Terms/>}/>
          <Route path="/Copyright" element={<Copyright/>}/>
          <Route path="/Cart" element={<Cart/>}/>
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
